import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  sliderbanner_create,
  sliderbanner_delete,
  sliderbanner_update,
  all_sliderbanner_list,
} from "../../actions/sliderbanner";
import { movie_name_listing } from "../../actions/Movie/movie";
import { series_name_listing } from "../../actions/WebSeries/series";
import { all_category_list } from "../../actions/Masters/category";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { all_subcategory_list } from "../../actions/Masters/subcategory";
import { Movie } from "@mui/icons-material";
export default function SliderBanner() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  // console.log(location, view, "5555");
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState("");
	const rights = useSelector((state) => state.layout.right);


  const path = location?.pathname.split("/")[2];

  const [tableData, setTableData] = useState({
    tableTitle: "Slider Banner",
    deleteRecord: sliderbanner_delete,
    updateRecord: sliderbanner_update,
		deleteAccess: rights?.["Slider Banner"]?.["delete"] == "true",

    customisedStatusUpdateMessage: true,
    onDeleteText: "Are you sure want to delete Slider Banner?",
    onActiveText: "Are you Sure want to Activate Slider Banner?",
    onInactiveText: "Are you Sure want to Inactivate Slider Banner?",
    tableHead: [
      {
        id: "category_type",
        label: "Category Type",
      },
      {
        id: "banner_name",
        label: "Video name",
      },

      {
        id: "app_banner",
        label: "App Image",
        isImage: true,
      },
      {
        id: "web_banner",
        label: "Web Image",
        isImage: true,
      },

      {
        id: "set_sequence",
        label: "Sequence",
        align: "center",
      },
      {
        id: "expiry_date1",
        label: "Expired on",
        isSpecial: true,
        // color:"red",
        align: "left",
      },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,

      },
      {
        id: "edit",
        label: "Update",
				// access: rights?.["Slider Banner"]?.["edit"] == "true"
				access: "true"


      },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "3",
        title: "Category",
        name: "category_type",
        options: ["Movie", "Series"],
      },
    ],
  });
  useEffect(() => {
    setView(path != "SliderBanner" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        ...form,
        set_sequence: tableData?.tableBody?.length + 1,
      }
    );
    setIsEdit(path == "EditSliderBanner");
  }, [location]);

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Select Category",
      name: "category_type",
      options: ["Movie",
       "Series"
      ],
      required: true,
    },

    {
      id: "2",
      type: "select",
      title: "Select Video",
      options: ["Movie1", "Movie2", "Movie3"],
      name: "movie",
      // display: "none",
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Select Series",
      options: ["Movie1", "Movie2", "Movie3"],
      name: "series",
      display: "none",
      required: true,
    },
    
    {
      id: "4",
      type: "lockedInput",
      variant: "number",
      title: "Set Sequence",
      name: "set_sequence",
      min: "1",
      max: "99",
      required: true,
    },
    {
      id: "20",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: "none",
      size: "3",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "4",
      type: "inputBox",
      variant: "date",
      title: "Expired on",
      min: new Date().toISOString().split("T")[0],
      name: "expiry_date",
      required: true,
    },

    {
      id: "6",
      type: "image",
      title: "Browse Web Banner",
      subtitle: "(Resolution : 1920 px * 1080px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      errorText: "File extension should be .mp4 only",
      name: "web_banner",
      required: true,
    },
    {
      id: "6",
      type: "image",
      title: "Browse App Banner",
      subtitle: "(Resolution : 980px x 1300px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      errorText: "File extension should be .mp4 only",
      name: "app_banner",
      required: true,
    },

    {
      id: "8",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      const temp = formStructure
      temp[4]["display"] = "block"
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const sliderbanners = useSelector(
    (state) => state.merchandise.slider_banners
  );
  // console.log(sliderbanners, "111111");
  useEffect(() => {
    dispatch(all_sliderbanner_list());
  }, []);
  const movies = useSelector((state) => state.movies.movie_name);
  // console.log(movies, "meeee");
  const series = useSelector((state) => state.webseries.series_name);
  const categories = useSelector((state) => state.masters.categories);
  const subcategories = useSelector((state) => state.masters.subcategories);
  // console.log(subcategories, "ssssssss");
  // console.log(categories, "caaaa");
  useEffect(() => {
    const data = new FormData();
    const data1 = new FormData();

    data.append("id", user?.id);
    data1.append("id", user?.id);
    data.append("movie_subcategory", "");



    dispatch(series_name_listing(data));
    dispatch(movie_name_listing(data));
    dispatch(all_category_list(data));
    dispatch(all_subcategory_list(data));
  }, [user]);

  // const parth = new Date()

  // setDate(
  // 	parth.getFullYear() + '-' + (parth.getMonth() + 1) + '-' + parth.getDate())
  useMemo(() => {
    if (sliderbanners?.statuscode == 200) {
      const temp = tableData;
      const today = new Date();
      today.setDate(today.getDate() - 1);
      temp.tableBody = sliderbanners?.data?.map((ele) => ({
        ...ele,
        expiry_date1:
          new Date(ele?.expiry_date) > today ? (
            <p>{ele?.expiry_date}</p>
          ) : (
            <p style={{ color: "red" }}>Expired</p>
          ),
        edit1: !(today <= new Date(ele?.expiry_date)),
        movie: ele?.banner_name,
        series: ele?.banner_name,
      }));

      setTableData({ ...temp });
      setForm({ ...form, set_sequence: tableData?.tableBody?.length + 1 });
    }
  }, [sliderbanners]);
  // console.log(sliderbanners, "4545445");

  const formTitle = isEdit ? "Edit Slider" : "Add Slider";

  // useMemo(()=>{},[isEdit])
  // 	if (categories?.statuscode == 200) {
  // 		const temp = formStructure;
  // 		temp[0]["options"] = categories?.data.map(
  // 			(category) => category?.category_name
  // 		);

  // 		setFormStructure([...temp]);
  // 		const tempFilter = tableData;
  // 		tempFilter["filterColumn"][0]["options"] = categories?.data.map(
  // 			(category) => category?.category_name
  // 		);

  // 		setTableData({ ...tempFilter });
  // 	}
  // }, [categories]);
  // useMemo(() => {
  // 	if (subcategories?.statuscode == 200) {
  // 		const temp = formStructure;
  // 		const tempForm = form;
  // 		const tempFilter = tableData;
  // 		tempFilter["filterColumn"][1]["options"] = subcategories?.data;

  // 		if (form?.category != undefined) {
  // 			temp[1]["options"] = subcategories?.data
  // 				.map(
  // 					(subcategory) =>
  // 						subcategory?.category_name == form?.category &&
  // 						subcategory?.subcategory_name
  // 				)
  // 				.filter((e) => e);
  // 			tempForm.sub_category = temp[1]["options"].includes(
  // 				form?.sub_category
  // 			)
  // 				? form?.sub_category
  // 				: "";
  // 		} else {
  // 			temp[1]["options"] = subcategories?.data.map(
  // 				(subcategory) => subcategory?.subcategory_name
  // 			);
  // 		}
  // 		setFormStructure([...temp]);
  // 		setForm({ ...tempForm });
  // 		setTableData({ ...tempFilter });
  // 	}
  // }, [subcategories, form?.category]);

  useMemo(() => {
    if (form?.category_type == "Movie") {
      if (movies?.statuscode == 200) {
        const temp = formStructure;
        const tempForm = form;
        const tempFilter = tableData;
        temp[1]["display"] = "block";
        temp[2]["display"] = "none";
        temp[1]["title"] = "Select Movie";
        temp[1]["options"] = movies?.data
          .map(
            (movie) =>movie?.movie_name
          )
          .filter((e) => e);
        //   tempFilter.filterColumn[1]["options"] =
        setFormStructure([...temp]);
        setForm({ ...tempForm });
        setTableData({ ...tempFilter });
      }
    } else if (form?.category_type == "Series") {
      if (series?.statuscode == 200) {
        const temp = formStructure;
        const tempForm = form;
        const tempFilter = tableData;
        temp[1]["display"] = "none";
        temp[2]["title"] = "Select Series";

        temp[2]["display"] = "block";
        temp[2]["options"] = series?.data
          .map((series) => series?.series_name)
          .filter((e) => e);

        setFormStructure([...temp]);
        setForm({ ...tempForm });
        setTableData({ ...tempFilter });
      }
    }

  }, [movies, series, form?.category_type, isEdit]);

  const message = useSelector((state) => state.layout.message);
  // console.log(movies, "8456");

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(form, "4444");
    const data = new FormData();
    const temp_movie_category = form;
    temp_movie_category["tempmovie"] = form?.["movie"];
    temp_movie_category["tempseries"] = form?.["series"];
    temp_movie_category["movie"] = movies?.data
      .map(
        (movie) =>
          form?.movie == movie?.movie_name &&
          movie.id
      )
      .filter((e) => e);
    temp_movie_category["series"] = series?.data
      .map((series) => form?.series == series?.series_name && series.id)
      .filter((e) => e);
    setForm({
      ...temp_movie_category,
    });

    Object.keys(form).map(
      (key) => key != "expired_on" && data.append(key, form?.[key])
    );
    const temp_form1 = form;
    temp_form1["banner_name"] = form?.["tempbanner_name"];
    temp_form1["movie"] = form?.["tempmovie"];
    temp_form1["series"] = form?.["tempseries"];
    setForm({
      ...temp_form1,
    });

    if (isEdit) {
      dispatch(sliderbanner_update(data));
      // console.log("parth gohel123");
    } else {
      dispatch(sliderbanner_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_sliderbanner_list());
      }, 1000);
      setView("view_all");
      navigate("/SliderBanner/SliderBanner/", { state: { view: "view_all" } });
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/SliderBanner/CreateSliderBanner/"}
        view_all={"/SliderBanner/SliderBanner/"}
        form={form}
        access={rights?.["Slider Banner"]?.["create"] == "true"}

      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/SliderBanner/EditSliderBanner"}
        />
      )}
    </>
  );
}
