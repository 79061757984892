
export  const formStructure =[
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Category Name",
        name:"category_name",
        regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
   
    {
        id:"3",
        type:"lockedInput",
        variant:"number",
        min:0,
        max:99,
        title:"Sequence",
        name:"sequence",
        required:true
    },
    {
        id: "20",
        type: "toggle",
        title: "Status",
        name: "status",
        default: "Active",
        display: "none",
        size: "3",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
  
    {
        id:"4",
        type:"image",
        title:"Upload Image",
        subtitle:"(Resolution : 512px x 512px) *",
        subsubtitle:"Max File Size 1MB",
        subsubsubtitle:"Support only JPG,PNG,JPEG",
        name:"category_image",
        required:true
    }
    
  ,
    {
        id:"6",
        type:"button",
        title:"Create",
        
    }
]