import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  genre_create,
  genre_delete,
  genre_update,
  all_genre_list,
} from "../../../actions/Masters/genre";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { flix_sub_ott_create, flix_sub_ott_list, flix_sub_ott_update } from "../../../actions/Masters/subott";
export default function SubOtt() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
	const rights = useSelector((state) => state.layout.right);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[3];
  useEffect(() => {
    setView(path != "SubOtt" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditSubOtt");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Sub Ott",
    // deleteRecord: genre_delete,
    disableDelete: true,
    
    deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    updateRecord: flix_sub_ott_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "ott_name",
        numeric: false,
        disablePadding: true,
        label: "Ott Name",
      },
      {
        id: "image",
        label: "Image",
        isImage: true,
      },
      {
        id:"status",
        label:"Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        access:"true"
      },
    ],
    tableBody: [],
  });
  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      title: "Add OTT",
      name: "ott_name",
      // maxLength: 15,
      // regex: /^[a-zA-Z\s\&\-]+$/,
      required: true,
    },

    {
      id: "2",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "Web OTT Link",
      // endsWith: ".m3u8",
      // errorText: "File extension should be .m3u8 only",
      name: "web_link",
      // size:"12",
      required: true,
    },
    {
      id: "3",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "App OTT Link",
      // endsWith: ".m3u8",
      // errorText: "File extension should be .m3u8 only",
      name: "app_link",
      // size:"12",
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      title: "App Store Id",
      regex: /^[0-9]+$/,
      // endsWith: ".m3u8",
      // errorText: "File extension should be .m3u8 only",
      name: "app_store_id",
      // size:"12",
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: "IOS Scheme",
      // endsWith: ".m3u8",
      // errorText: "File extension should be .m3u8 only",
      name: "ios_scheme",
      // size:"12",
      required: true,
    },
    {
      id: "6",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit?"block" : "none",
      size: "3",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "3",
      type: "image",
      title: "Image",
      name: "image",
      subtitle: "(Resolution : 512px x 512px) *",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      subsubtitle: "Max File Size 1MB",
    },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ];

  const SubOtts = useSelector((state) => state.masters.sub_ott_list);
  useEffect(() => {
    dispatch(flix_sub_ott_list());
  }, []);
  useMemo(() => {
    if (SubOtts?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = SubOtts?.data;
      setTableData({ ...temp });
    }
  }, [SubOtts]);

  const formTitle = isEdit ? "Edit Sub Ott" : "Create Sub Ott";

  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    const temp = tableData;
    if (isEdit) {
      dispatch(flix_sub_ott_update(data));
    } else {
      dispatch(flix_sub_ott_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(flix_sub_ott_list());
		navigate("/Masters/SubOtt/SubOtt", { state: { view: "view_all" } })
				setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/SubOtt/CreateSubOtt"}
        view_all={"/Masters/SubOtt/SubOtt"}
        access={rights?.["Masters"]?.["create"] == "true"}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/SubOtt/EditSubOtt"}
        />
      )}
    </>
  );
}
