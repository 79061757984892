import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SubOtt from '../components/Masters/SubOtt/SubOtt';

function SubOttModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateSubOtt/' element={<Layout children={SubOtt()} />} />
                < Route path='/EditSubOtt' element={<Layout children={SubOtt()} />} />
                < Route path='/SubOtt' element={<Layout children={SubOtt()} />} />
            </Routes>
        </div>
    )
}
export default SubOttModule