

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Category from '../components/Masters/Category/Category';
import SubCategory from '../components/Masters/SubCategory/SubCategory';
import Language from '../components/Masters/Language/Language';
import Genre from '../components/Masters/Genre/Genre';
import Cast from '../components/Masters/Cast/Cast';
import Layout from '../components/Layout/Layout';
import CastModule from './CastModule';
import CategoryModule from './CategoryModule';
import SubCategoryModule from './SubCategoryModule';
import LanguageModule from './LanguageModule';
import CountryModule from './CountryModule';
import GenreModule from './GenreModule';
import SubOttModule from './SubOttModule';
import SubAdminModule from './SubAdminModule';
import PaymentGateWay from '../components/Masters/PaymentGateWay/PaymentGateWay';

function MastersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Category/*' element={<CategoryModule key="Category"/>}/>
< Route path='/SubCategory/*' element={<SubCategoryModule key="SubCategory"/>}/>
< Route path='/SubOtt/*' element={<SubOttModule key="SubOtt"/>}/>

< Route path='/Language/*' element={<LanguageModule key="Language"/>}/>
< Route path='/Genre/*' element={<GenreModule key="Genre"/>}/>
< Route path='/Country/*' element={<CountryModule key="Country"/>}/>
< Route path='/Cast/*' element={<CastModule key="Cast"/>}/>
<Route path='/PaymentGateWay' element={<Layout children={PaymentGateWay()}/>}/>
< Route path='/SubAdmin/*' element={<SubAdminModule key="SubAdmin"/>}/>
      </Routes>
    </div>
  );
}

export default MastersModule;
