import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Export from "../utils/Export";
import Reload from "../utils/Reload";

import {
  customer_delete,
  customer_update,
  all_customer_list,
} from "./../../actions/customer";
import { all_subscription_list } from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
export default function Customer() {
  const dispatch = useDispatch();
  const rights = useSelector((state) => state.layout.right);
  const [tableData, setTableData] = useState({
    tableTitle: "Customers",
    deleteRecord: customer_delete,
    disableDelete: true,
    updateRecord: customer_update,
    onDeleteText: "Are you sure want to delete this customer?",
    deleteAccess:"true",
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure you want to activate this customer?",
    onInactiveText: [
      "Are you sure you want to inactivate this customer? ",
      "The customer will no longer be logged into the system once you inactivate it.",
    ],
    tableHead: [
      {
        id: "name",
        label: "Name",
        link: "/Customer/CustomerDetail/CustomerDetail",
        // subText: "isActive1",
        // color: "var(--gradientColor2)"
      },
      {
        id: "email",
        label: "Customer Info",
        subText: "mobile",
      },
      // {
      //   id: "socialType1",
      //   label: "Login",
      // },
      // {
      // 	id: "subscription",
      // 	label: "Subscription",
      // },
      {
        id: "deviceType",
        label: "Device",
        subText: "deviceId",
      },
      {
      	id: "userLocation",
      	label: "Location",
      },
      {
        id: "created_at",
        label: "Date",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
    tableBody: [],
    filterColumn: [
      // {
      // 	id: "1",
      // 	title: "Plan Type",
      // 	name: "subscription",
      // 	options: ["FREE", "TVOD", "SVOD"],
      // },
      // {
      //   id: "1",
      //   title: "Login By",
      //   name: "socialType1",
      //   options: ["Gmail", "Mobile"],
      // },
    ],
    isDateRangeFilter: "created_at",
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const customers = useSelector((state) => state.customers.customers);
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  const message = useSelector((state) => state.layout.message);

  useEffect(() => {
    // if(message?.statuscode ==200){

      dispatch(all_subscription_list());
      dispatch(all_customer_list());
    // }
  }, []);
  useMemo(() => {
    if (
      customers?.statuscode == 200 
    ) {
      const temp = tableData;
      temp.tableBody = customers?.data.map((ele) => ({
        ...ele,
        name: (
          <>
            <span style={{ fontWeight: "700" }}>{ele?.firstName}</span>{" "}
            <span style={{ fontWeight: "700" }}>{ele?.lastName}</span>
          </>
        ),
        subscription:
          ele?.subscription !== null ? ele?.subscription?.plan_name : " - ",
        mobile: ele?.mobileNumber !== null ? ele?.mobileNumber : " - ",
        deviceType : ele?.deviceType ? ele?.deviceType :"Web",
        deviceId : ele?.deviceId ? ele?.deviceId :"-",
        socialType1: ele?.socialType !== null ? "Gmail" : "Mobile",
        isActive1:
          ele?.isActive == "false" ? (
            <>
              <span style={{ color: "red" }}>Not Verified</span>
            </>
          ) : (
            <>
              <span style={{ color: "green" }}>Verified</span>
            </>
          ),
      }));
      setTableData({ ...temp });

      // if (customers?.isFull == "False") {
      //   // console.log("Cheril accha baccha hai", customers?.isFull);
      //   dispatch(
      //     all_customer_list({
      //       id: customers?.data[customers?.data.length - 1]?.id,
      //     })
      //   );
      // }
    }
  }, [customers]);
  // useEffect(() => {
  //   if(message?.statuscode ==200){

  //     dispatch(all_subscription_list());
  //     dispatch(all_customer_list());
  //   }
  // }, [message]);
  // console.log(customers,"3333")
  // useMemo(() => {

  // 	if (subscriptions?.statuscode == 200) {
  // 		const temp = tableData
  // 		temp.filterColumn[0]["options"] = subscriptions?.data.map((ele) => (ele?.plan_name))
  // 		setTableData({ ...temp })
  // 	}
  // }, [subscriptions])

  return (
    <>
      <Export
        fileName={"Customers"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
		access={rights?.["Customers"]?.["export_excel"] == "true"}
      />

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
