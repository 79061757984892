
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.right);
  const role = useSelector((state) => state?.layout?.role);
  return {
    tableTitle: "TV Shows",
    deleteAccess: rights?.["Series"]?.["delete"] == "true",
    tableHead: [
      {
        id: 'series_name',
        label: 'Show Name',
        link:"/Series/Series/SeriesDetails",
        subText :"total_episode1"
        // color:"#4267B2"
      },
      {
        id: 'series_poster',
        label: 'Image',
        isImage: true
      },
      {
        id: 'series_ownership',
        label: 'Ownership',
      },
      {
        id: 'series_type',
        label: 'Series Type',
      },
      {
        id: 'series_access',
        label: 'Access',
      },
      {
        id: 'rental_count',
        label: 'Total Rental',
      },


      {
        id: 'language',
        label: 'Language',
      },
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true

      },
    role !== "Distributor" &&  {
        id: 'edit',
        label: 'Update',
        access: rights?.["TV Shows"]?.["edit"] == "true"
      },
    ],
    tableBody: [
      
    ],
    filterColumn: [
      // {
      //   id: "1",
      //   title: "Access Type",
      //   name: "series_type",
      //   options: ["FREE", "TVOD", "SVOD"],
      // },
      {
        id: "2",
        title: "Language",
        name: "language",
        options: ["English", "Hindi", "Gujarati"],
      },

      {
        id: "4",
        title: "Series Type",
        name: "series_type",
        options: ["Web Series", "Super Short Series"],
      },
      {
        id: "1",
      title: "Ownership",
      name: "series_ownership",
      options:["In house","Collaborator"],
      },
    ]
  }
}